<template>
  <div class="min-h-screen flex">
    <Sidebar />
    <div class="w-2/12"></div>
    <div class="p-4 w-10/12">
      <div class="flex items-center mb-4">
        <div class="is-size-4">{{ $route.name }}</div>
      </div>
      <div class="flex items-center justify-between mb-4">
        <div class="flex items-center">
          <!-- <b-button
            icon-left="checkbox-marked-outline"
            class=""
            @click="(checkable = !checkable), (checkedRows = [])"
            :disabled="$store.getters.isProcessing"
            v-if="userHasPermission('write-items')"
            >Select</b-button
          > -->
          <!-- <b-button
            v-if="checkedRows.length"
            icon-left="delete-outline"
            class="ml-4"
            @click="onDelete()"
            :disabled="$store.getters.isProcessing"
            >Delete ({{ checkedRows.length }})</b-button
          > -->
          <!-- <b-button
            v-if="checkedRows.length"
            icon-left="close"
            class="ml-4"
            @click="checkedRows = []"
            :disabled="$store.getters.isProcessing"
            >Clear</b-button
          > -->
        </div>
        <div @keyup.enter="onSearch">
          <b-input
            v-model="search"
            icon="magnify"
            placeholder="Search"
          ></b-input>
        </div>
      </div>
      <b-table
        :data="data.data"
        :loading="$store.getters.isLoading && !$store.getters.isRouting"
        paginated
        pagination-position="bottom"
        backend-pagination
        :total="data.total"
        :per-page="data.per_page"
        :current-page="data.current_page"
        @page-change="onPageChange"
        backend-sorting
        :default-sort="[$store.state.items.column, $store.state.items.order]"
        @sort="onSort"
        striped
        :checkable="checkable"
        checkbox-position="left"
        :checked-rows.sync="checkedRows"
      >
        <!-- columns -->
        <b-table-column field="product" label="Image" width="20" v-slot="props">
          <b-image
            :src="
              props.row.product.image
                ? props.row.product.image.thumb
                : $default_image_thumb
            "
            :alt="props.row.product.slug"
            ratio="1by1"
            lazy
          ></b-image>
        </b-table-column>
        <b-table-column field="code" label="ID" v-slot="props" sortable>
          <div>{{ props.row.code }}</div>
          <div>
            <router-link
              v-if="userHasPermission('write-items')"
              :to="{ name: 'Edit Item', params: { id: props.row.id } }"
              class="is-size-7 is-link px-2 border"
              >Update</router-link
            >
          </div>
        </b-table-column>
        <!-- <b-table-column field="order" label="Client" v-slot="props" sortable>
          <div>{{ props.row.order.user.name }}</div>
        </b-table-column> -->
        <b-table-column
          field="stage_id"
          label="Location"
          v-slot="props"
          sortable
        >
          <div>{{ props.row.stage.name }}</div>
        </b-table-column>
        <b-table-column field="remarks" label="Remarks" v-slot="props" sortable>
          <div>{{ props.row.remarks ? "Yes" : "No" }}</div>
        </b-table-column>
        <b-table-column field="joborder" label="Project Name" v-slot="props">
          <div>{{ props.row.joborder.project_name || "none" }}</div>
        </b-table-column>
        <b-table-column field="joborder" label="ETD" v-slot="props">
          <div>{{ props.row.joborder.etd || "none" }}</div>
        </b-table-column>
        <b-table-column field="joborder" label="ETA" v-slot="props">
          <div>{{ props.row.joborder.eta || "none" }}</div>
        </b-table-column>
        <b-table-column
          field="updated_at"
          label="Updated"
          v-slot="props"
          sortable
        >
          {{ props.row.updated_at }}
        </b-table-column>
        <b-table-column
          field="created_at"
          label="Created"
          v-slot="props"
          sortable
        >
          {{ props.row.created_at }}
        </b-table-column>
        <!-- !columns -->
      </b-table>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/admin/Sidebar";
import store from "@/store";
export default {
  components: {
    Sidebar
  },
  data: () => ({
    checkable: false,
    checkedRows: []
  }),
  computed: {
    data() {
      return this.$store.state.items.data;
    },
    search: {
      get() {
        return this.$store.state.items.search;
      },
      set(newValue) {
        store.commit("setItemsSearch", newValue);
      }
    }
  },
  watch: {
    search: _.debounce(function() {
      if (!this.search) this.onSearch();
    }, store.getters.delay)
  },
  methods: {
    userHasPermission(data) {
      return store.getters.userHasPermission(data);
    },
    onSearch() {
      store.dispatch(this.$route.meta.dispatch);
    },
    onPageChange(page) {
      store.commit("setItemsPage", page);
      store.dispatch(this.$route.meta.dispatch);
    },
    onSort(column, order) {
      store.commit("setItemsPage", 1);
      store.commit("setItemsColumn", column);
      store.commit("setItemsOrder", order);
      store.dispatch(this.$route.meta.dispatch);
    },
    onDelete() {
      store
        .dispatch("deleteSelection", {
          message: `Do you really want to delete these items?`,
          action: "deleteSelection",
          ids: _.map(this.checkedRows, "id"),
          path: "items",
          push: "Items",
          dispatch: this.$route.meta.dispatch
        })
        .then(() => {
          this.checkedRows = [];
          this.checkable = false;
        });
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta.dispatch) {
      store.dispatch(to.meta.dispatch, {}).then(() => {
        next();
      });
    }
  }
};
</script>
